<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block/>

        {{ page_title }}

        <small v-if="tasks_statistic" class="font-light">
          ({{ tasks_statistic.sellers }})
        </small>

        <button type="button"
                class="btn btn-success pull-right"
                v-on:click="showCreateQuickTaskModal"
                title="Добавить задачу">
          <i class="fa fa-plus"></i>
        </button>
      </h3>

      <div v-if="[1, 2, 7, 8, 9].includes(this.$store.getters['auth/authUser'].role.id) && !onProgress"
           class="form-group mt-3">
        <input type="radio" class="btn-check" name="options-outlined" id="show_only-my"
               @input="() => {
                 filter_tasks.show_only = 'my';
                 getTasks();
               }" autocomplete="off" :checked="filter_tasks.show_only === 'my'">
        <label class="btn btn-outline-primary" for="show_only-my">Мои</label>

        <input type="radio" class="btn-check" name="options-outlined" id="show_only-all"
               @input="() => {
                 filter_tasks.show_only = 'all';
                 getTasks();
               }" autocomplete="off" :checked="filter_tasks.show_only === 'all'">
        <label class="btn btn-outline-primary" for="show_only-all">Все</label>
      </div>
    </div>

    <div class="box-body">
      <transition name="fade">
        <div class="form-group">
          <div class="row">
            <label class="col-xl-3 col-md-6 col-sm-12 mb-1" for="filter-by-branch">
              <multiselect :allow-empty="true"
                           :options="branches"
                           :searchable="false"
                           :show-labels="true"
                           id="filter-by-branch"
                           @input="() => {
                             filter_tasks.branch_id = branch ? branch.id : null;
                             getEmployees(filter_tasks.branch_id);
                             getTasks();
                           }"
                           deselect-label="Убрать"
                           label="name"
                           placeholder="Филиал"
                           required
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="Филиал"
                           track-by="id"
                           v-model="branch">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
                <template slot="singleLabel" slot-scope="props">
                  {{ props.option.title }}, {{ props.option.address }}
                </template>
                <template slot="option" slot-scope="props">
                  {{ props.option.title }}, <span class="text-muted">{{ props.option.address }}</span>
                </template>
              </multiselect>
            </label>

            <label v-if="filter_tasks.show_only !== 'my'"
                   class="col-xl-3 col-md-6 col-sm-12 mb-1" for="filter-by-responsible">
              <multiselect :allow-empty="true"
                           :options="employees"
                           :searchable="true"
                           id="filter-by-responsible"
                           @input="() => {
                             filter_tasks.responsible_id = responsible ? responsible.id : null;
                             getTasks();
                           }"
                           deselect-label="Убрать"
                           label="title"
                           placeholder="Ответственный"
                           required
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="Ответственный"
                           track-by="id"
                           v-model="responsible">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
              </multiselect>
            </label>
          </div>
        </div>
      </transition>

      <div class="table-responsive" v-if="task_type_states.length && !onProgress">
        <table class="table table-condensed table-bordered">
          <thead>
          <tr>
            <th v-for="tasks_state in task_type_states"
                :style="'background:' + tasks_state.custom_color.replace('0.25', '0.5')">
              {{ tasks_state.name }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td v-bind:style="'width: ' + (column_width ? (column_width + '%') : 'auto')">
              <div v-for="task in tasks.filter(task => { return task.type_state_id === 301; })">
                <task-seller-item :task="task" :status_custom_color="task.type_status.custom_color"/>
              </div>
            </td>
            <td v-bind:style="'width: ' + (column_width ? (column_width + '%') : 'auto')">
              <div v-for="task in tasks.filter(task => { return task.type_state_id === 302; })">
                <task-seller-item :task="task" :status_custom_color="task.type_status.custom_color"/>
              </div>
            </td>
            <td v-bind:style="'width: ' + (column_width ? (column_width + '%') : 'auto')">
              <div v-for="task in tasks.filter(task => { return task.type_state_id === 303; })">
                <task-seller-item :task="task" :status_custom_color="task.type_status.custom_color"/>
              </div>
            </td>
            <td v-bind:style="'width: ' + (column_width ? (column_width + '%') : 'auto')">
              <div v-for="task in tasks.filter(task => { return task.type_state_id === 304; })">
                <task-seller-item :task="task" :status_custom_color="task.type_status.custom_color"/>
              </div>
            </td>
            <td v-bind:style="'width: ' + (column_width ? (column_width + '%') : 'auto')">
              <div v-for="task in tasks.filter(task => { return task.type_state_id === 305; })">
                <task-seller-item :task="task" :status_custom_color="task.type_status.custom_color"/>
              </div>
            </td>
            <td v-bind:style="'width: ' + (column_width ? (column_width + '%') : 'auto')">
              <div v-for="task in tasks.filter(task => { return task.type_state_id === 306; })">
                <task-seller-item :task="task" :status_custom_color="task.type_status.custom_color"/>
              </div>
            </td>
            <td v-bind:style="'width: ' + (column_width ? (column_width + '%') : 'auto')">
              <div v-for="task in tasks.filter(task => { return task.type_state_id === 307; })">
                <task-seller-item :task="task" :status_custom_color="task.type_status.custom_color"/>
              </div>
            </td>
            <td v-bind:style="'width: ' + (column_width ? (column_width + '%') : 'auto')">
              <div v-for="task in tasks.filter(task => { return task.type_state_id === 308; })">
                <task-seller-item :task="task" :status_custom_color="task.type_status.custom_color"/>
              </div>
            </td>
            <td v-bind:style="'width: ' + (column_width ? (column_width + '%') : 'auto')">
              <div v-for="task in tasks.filter(task => { return task.type_state_id === 309; })">
                <task-seller-item :task="task" :status_custom_color="task.type_status.custom_color"/>
              </div>
            </td>
            <td v-bind:style="'width: ' + (column_width ? (column_width + '%') : 'auto')">
              <div v-for="task in tasks.filter(task => { return task.type_state_id === 310; })">
                <task-seller-item :task="task" :status_custom_color="task.type_status.custom_color"/>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <loading-processing v-else></loading-processing>

      <div class="panel panel-default" v-if="tasks.length === 0 && !onProgress">
        <div class="panel-body text-center text-muted mt-5 mb-5">
          Нет элементов для отображения
        </div>
      </div>
    </div>

    <modal :adaptive="true"
           :clickToClose="true"
           :draggable="false"
           :name="'create_quick_task_modal'"
           :resizable="false"
           :scrollable="false"
           :shiftX="0.5"
           :shiftY="0.5"
           :height="'auto'"
           overlayTransition="fade"
           transition="fade"
           @closed="hideCreateQuickTaskModal">
      <div class="box box-primary box-modal mb-0">
        <div class="box-header with-border mb-3">
          <h4>Создать задачу</h4>
        </div>

        <div class="box-body">
          <create-quick-task-form :tasks_type_id="filter_tasks.type_id"/>
        </div>
      </div>
    </modal>

    <modal v-if="currentTask"
           :adaptive="true"
           :clickToClose="true"
           :draggable="false"
           :name="'change_task_modal_' + currentTask.id"
           :resizable="false"
           :scrollable="false"
           :shiftX="0.5"
           :shiftY="0.5"
           :height="'auto'"
           overlayTransition="fade"
           transition="fade"
           @closed="hideChangeTaskModal">
      <div class="box box-primary box-modal mb-0">
        <div class="box-header with-border mb-3">
          <h4>#{{ currentTask.id }}</h4>
        </div>

        <div class="box-body">
          <edit-task-state-form :task="currentTask"/>
        </div>
      </div>
    </modal>
  </div>

</template>

<script>
import Vue from "vue";
import BranchService from "@/services/BranchService";
import Multiselect from 'vue-multiselect';
import VModal from 'vue-js-modal';

Vue.use(VModal);

Vue.component('task-seller-item', (resolve) => {
  return require(['./item'], resolve);
});

Vue.component('create-quick-task-form', (resolve) => {
  return require(['./../create-quick-form'], resolve);
});

Vue.component('edit-task-state-form', (resolve) => {
  return require(['./../../details/edit-state-form'], resolve);
});

export default {
  name: 'funnel-sellers',

  components: {
    Multiselect,
  },

  metaInfo() {
    return {
      title: this.page_title,
    }
  },

  data: () => ({
    page_title: 'Продавцы',

    task_type_states: [],
    tasks: [],
    tasks_statistic: null,

    branches: [],
    branch: null,
    employees: [],
    responsible: null,

    currentTask: null,

    filter_tasks: {
      type_id: 30,
      only_with_state: true,
      show_only: null,
      type_status_id: null,
      branch_id: null,
      responsible_id: null,
    },

    column_width: null,

    onProgress: true,
  }),

  async created() {
    try {
      this.EventBus.$on('task-items-reload', this.getTasks);

      this.EventBus.$on('hide-quick-create-task-modal', () => {
        this.hideCreateQuickTaskModal();
      });
      this.EventBus.$on('open-edit-task-modal', data => {
        this.currentTask = data.task;
        this.showChangeTaskModal();
      });
      this.EventBus.$on('hide-edit-task-modal', () => {
        this.hideChangeTaskModal();
      });

      if ([1, 2, 7, 8, 9].includes(this.$store.getters['auth/authUser'].role.id)) {
        this.filter_tasks.show_only = 'all';

        const employees_response = await API.apiClient.get('/employees', {
          params: this.$apiRequestParams.filters_list
        });

        this.employees = employees_response.data;
      } else {
        this.filter_tasks.show_only = 'my';
      }

      // if (localStorage.getItem('filter_tasks_sellers')) {
      //   this.filter_tasks = JSON.parse(localStorage.getItem('filter_tasks_sellers'));
      //   if (this.filter_tasks.branch_id) {
      //     this.branch = this.branches.find((branch) => {
      //       return branch.id === this.filter_tasks.branch_id;
      //     });
      //   }
      //   if (this.filter_tasks.responsible_id) {
      //     this.responsible = this.employees.find((employee) => {
      //       return employee.id === this.filter_tasks.responsible_id;
      //     });
      //   }
      // }

      this.branches = await BranchService.getItems();

      await this.getTaskTypeStates();
      await this.getTasks();
      await this.getTasksStatistic();
    } catch (error) {
      errorHandler(error);
    }

    this.onProgress = false;
  },

  methods: {
    async getTaskTypeStates() {
      try {
        const task_type_states_response = await API.apiClient.get(
            '/tasks/type/' + this.filter_tasks.type_id + '/states'
        );

        this.task_type_states = task_type_states_response.data;

        this.column_width = 100 / this.task_type_states.length;
      } catch (error) {
        errorHandler(error);
      }
    },
    async getTasks() {
      try {
        this.onProgress = true;

        // localStorage.setItem('filter_tasks_sellers', JSON.stringify(this.filter_tasks));

        const tasks_response = await API.apiClient.get('/tasks', {
          params: {
            filters_query: this.filter_tasks,
            relations: [
              'car.branch',
              'client',
              'type',
              'type.states',
              'type_state',
              'type_status',
              'responsible.branch',
              'creator',
              'branch',
              'last_comment',
            ],
            sorting: 'asc',
            sort_column: 'id',
          }
        });

        this.tasks = tasks_response.data;
      } catch (error) {
        errorHandler(error);
      }
      this.onProgress = false;
    },

    async getTasksStatistic() {
      try {
        const tasks_statistic_response = await API.apiClient.get('/tasks/statistic/count');

        this.tasks_statistic = tasks_statistic_response.data;
      } catch (error) {
        errorHandler(error);
      }
    },

    async getEmployees(branchID = null) {
      try {
        if ([1, 2, 7, 8, 9].includes(this.$store.getters['auth/authUser'].role.id)) {

          const employees_response = await API.apiClient.get('/employees', {
            params: {
              filters_query: {
                is_paginated: 0,
                branch_id: branchID
              },
            }
          });

          this.employees = employees_response.data;
        }
      } catch (error) {
        errorHandler(error);
      }
    },

    showCreateQuickTaskModal() {
      this.$modal.show('create_quick_task_modal');
    },
    hideCreateQuickTaskModal() {
      this.$modal.hide('create_quick_task_modal');
    },

    showChangeTaskModal() {
      setTimeout(() => {
        this.$modal.show('change_task_modal_' + this.currentTask.id);
      }, 100);
    },
    hideChangeTaskModal() {
      this.$modal.hide('change_task_modal_' + this.currentTask.id);
      this.currentTask = null;
    },
  },
}
</script>

<style scoped>
tr > td {
  padding: 10px;
}

tr:hover {
  background: unset;
}

td:hover {
  background: unset;
}
</style>

<style scoped>
th {
  border-bottom: 0 !important;
}

tr > td {
  padding: 0 !important;
}
</style>
